import {useEffect, useState} from "react";
import {Box, Button, Grid, Paper, TextField, Typography} from "@mui/material";

const POST_URL = "https://serverless-api.alexander-rose-11.workers.dev/api/posts"


const Body = () => {
    const [postData, setPostData] = useState([])
    const [title, setTitle] = useState('')
    const [username, setUsername] = useState('')
    const [content, setContent] = useState('')

    const fetchPosts = async () => {
        await fetch(POST_URL)
            .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json()
                    setPostData(data.map(c => JSON.parse(c)))
                }
            })
            .catch(error => {})
    };

    useEffect(() => {
        fetchPosts().then()
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const newPost = {
                title: title,
                username: username,
                content: content
            }
            const init = {
                method: 'POST',
                body: JSON.stringify(newPost)
            }
            const addPostRes = fetch(POST_URL, init)
            const newPostData = [newPost, ...postData]
            setPostData(newPostData)
        } finally {
            setTitle('')
            setUsername('')
            setContent('')
        }
    }

    return (
        <Box pt={5} pb={10}>
            <Grid
                container
                direction='column'
                spacing={5}
            >
                <Grid item>
                    <Paper variant='outlined'>
                        <Box p={5}>
                            <Grid
                                container
                                direction='column'
                                spacing={2}
                            >
                                <Grid item>
                                    <Typography
                                        variant='h5'
                                        color='primary'
                                    >
                                        Add your own Post
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant='caption'
                                    >
                                        It takes a moment for a new KV Post to be added so please wait a few seconds if a new Posts hasn't show up immediately upon page refresh
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <form onSubmit={handleSubmit}>
                                        <Grid
                                            container
                                            direction='column'
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    maxLength={32}
                                                    label='Title'
                                                    variant='filled'
                                                    size='small'
                                                    value={title}
                                                    onChange={e => setTitle(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    maxLength={32}
                                                    label='Username'
                                                    variant='filled'
                                                    size='small'
                                                    value={username}
                                                    onChange={e => setUsername(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    multiline
                                                    maxLength={128}
                                                    label='Content'
                                                    variant='filled'
                                                    size='small'
                                                    value={content}
                                                    onChange={e => setContent(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    fullWidth
                                                    variant='outlined'
                                                    type='submit'
                                                >
                                                    Create a new Post
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
                { postData.map( (c, i) =>
                    <Grid
                        key={i}
                        item
                    >
                        <Paper>
                            <Box p={5}>
                                <Grid
                                    container
                                    direction='column'
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Typography
                                            variant='body1'
                                        >
                                            {c?.title}
                                        </Typography>
                                        <Typography
                                            variant='caption'
                                        >
                                            - {c?.username}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant='body2'
                                        >
                                            {c?.content}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default Body;
