import ThemeToggle from "./theme/ThemeToggle";
import {AppBar, Grid, Icon, Typography} from "@mui/material";
import {FaCloudflare} from 'react-icons/fa';

const Header = () => {
    return (
        <AppBar
            position='relative'
            color='transparent'
            elevation={0}
        >
            <Grid
                container
                justifyContent='space-between'
            >
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item>
                            <Icon
                                color='secondary'
                            >
                                <FaCloudflare />
                            </Icon>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Cloudflare General Assignment
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <ThemeToggle />
                </Grid>
            </Grid>
        </AppBar>
    );
};

export default Header;
